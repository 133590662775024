import React from 'react'
import { WoofMenu } from 'woof-components/src/woof-menu/WoofMenu'
import styled from 'styled-components'
import { WoofStakingDisclaimer } from './WoofStakingDisclaimer'

const Container = styled.div`
    display: flex;
    flex-flow: column;
    background-color: #141414;
`

function App() {
    return (
        <>
            <Container>
                <WoofMenu includeWalletConnector={false} includeLogo={true} />
                <WoofStakingDisclaimer />
            </Container>
        </>
    )
}

// Configure live reload
new EventSource('/esbuild').addEventListener('change', () => location.reload())

export default App
