import React from 'react'
import { LogoAndMobileWalletSelectorWrapper } from './menu-styles'
import { WoofWalletSelectButton } from './WoofWalletConnector'
import WoofLogoImage from './assets/woof-logo.png'

export const WoofLogoAndMobileWalletConnector = ({
    includeWalletConnector = true,
    includeLogo = true,
}: {
    includeWalletConnector?: boolean
    includeLogo?: boolean
}): JSX.Element | null => {
    return includeLogo || includeWalletConnector ? (
        <LogoAndMobileWalletSelectorWrapper>
            {includeLogo && (
                <img
                    className="woof-logo"
                    src={WoofLogoImage}
                    alt="WOOF logo"
                    style={{ opacity: 1 }}
                />
            )}

            {includeWalletConnector && <WoofWalletSelectButton />}
        </LogoAndMobileWalletSelectorWrapper>
    ) : null
}
