import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  #woof-menu-root {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #141414;
  }
  
  @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
  `
