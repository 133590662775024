import homeThumbnail from './assets/home.png'
import dexThumbnail from './assets/dex.png'
import playgroundThumbnail from './assets/playground.png'
import stakeLegacyThumbnail from './assets/stake-legacy.jpg'
import websiteThumbnail from './assets/website.png'
import stakingThumbnail from './assets/woofers-staking.png'

export const MenuItems = [
    {
        title: 'Home',
        link: 'https://links.woof.ventures',
        external: true,
        thumbnail: homeThumbnail,
    },
    {
        title: 'Website',
        link: 'https://woof.ventures',
        external: true,
        thumbnail: websiteThumbnail,
    },
    {
        title: 'Trade',
        link: 'https://dex.woof.ventures',
        thumbnail: dexThumbnail,
    },
    {
        title: 'NFTs',
        children: [
            {
                title: 'WOOFers Playground',
                link: 'https://woofers-playground.woof.ventures',
                external: true,
                thumbnail: playgroundThumbnail,
            },
            {
                title: 'WOOFer Staking',
                link: 'https://staking.woof.ventures',
                external: true,
                thumbnail: stakingThumbnail,
            },
            {
                title: 'WOOFer Staking (decommissioned)',
                link: 'https://stake-legacy.woof.ventures',
                external: true,
                thumbnail: stakeLegacyThumbnail,
            },
        ],
    },
]
