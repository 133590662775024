import React, { useState, useRef, useEffect } from 'react'
import { MenuItems } from './MenuItems'
import { WoofLogoAndMobileWalletConnector } from './WoofLogoAndMobileWalletConnector'
import { WoofWalletSelectButton } from './WoofWalletConnector'
import {
    Wrapper,
    MenuWrapper,
    Menu,
    MenuItemChildrenWrapper,
    WalletSelectorWrapper,
    WoofMobileContentSpacer,
} from './menu-styles'

import { GlobalStyle } from './globalStyles'

export const WoofMenu = ({
    includeWalletConnector = true,
    includeLogo = true,
}: {
    includeWalletConnector?: boolean
    includeLogo?: boolean
}) => {
    const [subMenuOpen, setSubMenuOpen] = useState(false)
    const [thumbnail, setThumbnail] = useState<string | null>(null)

    const subMenuAndChildren = useRef<HTMLDivElement>(null)

    const closeOpenMenus = (e: MouseEvent) => {
        if (
            subMenuAndChildren.current &&
            subMenuOpen &&
            !subMenuAndChildren.current.contains(e.target as Node)
        ) {
            setSubMenuOpen(false)
        }
    }

    // Preload thumbnails
    useEffect(() => {
        MenuItems.forEach(({ thumbnail, children }) => {
            if (thumbnail) {
                const img = new Image()
                img.src = thumbnail
            }
            if (children) {
                children.forEach((child) => {
                    if (child.thumbnail) {
                        const img = new Image()
                        img.src = child.thumbnail
                    }
                })
            }
        })
    }, [])

    document.addEventListener('mousedown', closeOpenMenus)

    return (
        <>
            <GlobalStyle />
            <Wrapper>
                <WoofLogoAndMobileWalletConnector
                    includeWalletConnector={includeWalletConnector}
                    includeLogo={includeLogo}
                />

                <MenuWrapper>
                    <Menu>
                        {MenuItems.map((item, index) => {
                            if (!item.children) {
                                return (
                                    <a
                                        key={item.title}
                                        href={item.link}
                                        tabIndex={index}
                                        target={
                                            item.external ? '_blank' : '_self'
                                        }
                                        rel="noopener noreferrer"
                                        onMouseOver={() => {
                                            if (item.thumbnail) {
                                                setThumbnail(item.thumbnail)
                                            }
                                        }}
                                        className={
                                            item.link.startsWith(
                                                window.location.href
                                            )
                                                ? 'active'
                                                : ''
                                        }
                                        onMouseOut={() => setThumbnail(null)}
                                    >
                                        {item.title}
                                    </a>
                                )
                            } else {
                                return (
                                    <div
                                        className={'menu-button'}
                                        key={item.title}
                                        tabIndex={index}
                                        ref={subMenuAndChildren}
                                        onClick={() =>
                                            setSubMenuOpen(!subMenuOpen)
                                        }
                                    >
                                        {item.title}{' '}
                                        <>{subMenuOpen ? '\u290A' : '\u290B'}</>
                                        {subMenuOpen && (
                                            <MenuItemChildrenWrapper
                                                className={'menu-item-children'}
                                                tabIndex={5}
                                                onBlur={() =>
                                                    setSubMenuOpen(false)
                                                }
                                                includeLogo={includeLogo}
                                                includeWalletConnector={
                                                    includeWalletConnector
                                                }
                                            >
                                                {item.children.map(
                                                    (child, index) => (
                                                        <a
                                                            key={child.title}
                                                            tabIndex={5 + index}
                                                            href={child.link}
                                                            target={
                                                                child.external
                                                                    ? '_blank'
                                                                    : '_self'
                                                            }
                                                            rel="noopener noreferrer"
                                                            onMouseOver={() => {
                                                                if (
                                                                    child.thumbnail
                                                                ) {
                                                                    setThumbnail(
                                                                        child.thumbnail
                                                                    )
                                                                }
                                                            }}
                                                            onMouseOut={() =>
                                                                setThumbnail(
                                                                    null
                                                                )
                                                            }
                                                        >
                                                            {child.title}
                                                        </a>
                                                    )
                                                )}
                                            </MenuItemChildrenWrapper>
                                        )}
                                    </div>
                                )
                            }
                        })}
                    </Menu>
                </MenuWrapper>

                {(includeLogo || includeWalletConnector) && (
                    <WalletSelectorWrapper>
                        {includeWalletConnector && <WoofWalletSelectButton />}
                    </WalletSelectorWrapper>
                )}

                {thumbnail && (
                    <div className="thumbnail">
                        <img src={thumbnail} alt="thumbnail" />
                    </div>
                )}
            </Wrapper>
            <WoofMobileContentSpacer
                includeWalletConnector={includeWalletConnector}
                includeLogo={includeLogo}
            />
        </>
    )
}
