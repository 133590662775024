import styled from 'styled-components'

export const WoofMobileContentSpacer = styled.div<{
    includeLogo: boolean
    includeWalletConnector: boolean
}>`
    min-height: ${(props) =>
        !props.includeLogo && !props.includeWalletConnector ? '66px' : '130px'};
    @media screen and (min-width: 992px) {
        min-height: 0px;
    }
`

export const Wrapper = styled.div`
    line-height: 64px;
    background-color: #141414;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    position: fixed;
    width: 100%;
    flex-flow: column;
    z-index: 10;
    top: 0;

    div.thumbnail {
        display: none;

        @media screen and (min-width: 992px) {
            display: flex;
            position: fixed;
            top: 80px;
            margin-right: 120px;
            padding: 16px;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            z-index: 10;
            padding-top: 8px;

            width: 300px;
            height: 300px;

            img {
                display: flex;
                max-width: 300px;
                max-height: 300px;
                height: auto;
                border-radius: 16px;
                box-shadow: inset 0 0 20px 20px;
                box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.25);
            }
        }
    }

    @media screen and (min-width: 992px) {
        font-size: 20px;
        position: static;
        flex-flow: row;
    }
`

export const MenuWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media screen and (min-width: 992px) {
        margin-bottom: 0;
    }
`

export const Menu = styled.div`
    justify-content: center;
    display: inline-flex;
    background-color: rgba(0, 0, 0, 0.3);
    height: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-top: 1px solid #222;
    border-bottom: 1px solid #222;

    a,
    div.menu-button {
        display: flex;
        font-weight: 400;
        color: #fff;
        text-align: center;
        align-items: center;
        justify-content: center;
        max-height: 80%;
        cursor: pointer;
        width: 100%;
        position: relative;
        border-radius: 16px;
        transition: color 0s;
        text-decoration: none;

        @media screen and (min-width: 992px) {
            padding: 0px 24px 0px 24px;
            width: auto;
        }

        &.active {
            color: #06fcb2;
        }

        &:hover {
            color: #fff;
            animation: colorPulse 0.7s infinite;

            @keyframes colorPulse {
                0% {
                    color: rgba(6, 252, 178, 0.75);
                }
                50% {
                    color: rgba(6, 252, 178, 1);
                }
                100% {
                    color: rgba(6, 252, 178, 0.75);
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        border-radius: 16px;
        width: auto;
        border: 0;
    }
`

export const MenuItemChildrenWrapper = styled.div<{
    includeLogo: boolean
    includeWalletConnector: boolean
}>`
    position: fixed;
    top: ${(props) =>
        !props.includeLogo && !props.includeWalletConnector ? '66px' : '130px'};
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 0;
    left: 0;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    z-index: 10;

    a {
        margin: 0;
        padding: 0;
    }

    @media screen and (min-width: 992px) {
        position: absolute;
        border-radius: 16px;
        right: auto;
        width: 300px;
        left: 20px;
        top: 64px;
    }
`

export const LogoAndMobileWalletSelectorWrapper = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    cursor: pointer;
    width: 100%;
    padding: 8px 16px 8px 16px;
    min-height: 64px;

    img.woof-logo {
        margin-left: 8px;
        height: 30px;

        @media screen and (min-width: 992px) {
            margin-left: 24px;
        }
    }

    .woof-connect-wallet-container {
        display: flex;
        justify-content: end;
        width: 100%;
        margin-right: 8px;
        align-items: center;

        @media screen and (min-width: 992px) {
            margin-right: 24px;
        }
    }

    @media screen and (min-width: 992px) {
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
        width: 15%;
        height: 100%;

        .woof-connect-wallet-container {
            display: none;
        }
    }
`

export const WalletSelectorWrapper = styled.div`
    display: none;
    width: 50%;
    margin-right: 24px;
    align-items: center;

    @media screen and (min-width: 992px) {
        width: 15%;
        display: flex;
        margin-bottom: 0;
        justify-content: right;
        height: 100%;
    }
`
