import React, { useMemo } from 'react'
import styled from 'styled-components'
import {
    WalletProvider,
    ConnectionProvider,
} from '@solana/wallet-adapter-react'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import {
    Coin98WalletAdapter,
    HuobiWalletAdapter,
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets'
import {
    WalletModalProvider,
    WalletMultiButton,
} from '@solana/wallet-adapter-react-ui'

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css')

const WalletButtonContainer = styled.div`
    .woof-connect-wallet-button {
        height: 3rem;
        box-shadow: 0 10px 15px -3px rgb(0 0 0/0.1),
            0 4px 6px -4px rgb(0 0 0/0.1);
        border-radius: 0.5rem;
        font-family: inherit;
        background: rgba(220, 31, 255, 0.75);
        background: linear-gradient(
            90deg,
            rgba(220, 31, 255, 0.75) 0%,
            rgba(6, 252, 178, 0.75) 100%
        );
        padding: 0.5rem 1rem;
        font-weight: 500;
        font-size: 0.9rem;
        align-items: center;

        &:not([disabled]) {
            &:hover {
                background: rgba(220, 31, 255, 0.9);
                background: linear-gradient(
                    90deg,
                    rgba(220, 31, 255, 0.9) 0%,
                    rgba(6, 252, 178, 0.9) 100%
                );
            }
        }

        &:disabled {
            background: rgba(220, 31, 255, 0.9);
            background: linear-gradient(
                90deg,
                rgba(220, 31, 255, 0.9) 0%,
                rgba(6, 252, 178, 0.9) 100%
            );
            color: #fff;
        }
    }

    .wallet-adapter-dropdown {
        .wallet-adapter-dropdown-list-item {
            &:not([disabled]) {
                &:hover {
                    background-color: #2c2d30;
                    color: #06fcb2;
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        margin-right: 0;
    }
`

export const WOOF_RPC_ENDPOINT =
    'https://indulgent-delicate-cherry.solana-mainnet.discover.quiknode.pro/344a1a4563b543693b859b243976092a4c71b212/'

export const Wallet = (props: React.PropsWithChildren) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet

    const wallets = useMemo(
        () => [
            /**
             * Wallets that implement either of these standards will be available automatically.
             *
             *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
             *     (https://github.com/solana-mobile/mobile-wallet-adapter)
             *   - Solana Wallet Standard
             *     (https://github.com/solana-labs/wallet-standard)
             *
             * If you wish to support a wallet that supports neither of those standards,
             * instantiate its legacy wallet adapter here. Common legacy adapters can be found
             * in the npm package `@solana/wallet-adapter-wallets`.
             */
            new PhantomWalletAdapter(),
            new Coin98WalletAdapter(),
            new SolflareWalletAdapter(),
            new LedgerWalletAdapter(),
            new TorusWalletAdapter(),
            new HuobiWalletAdapter(),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [network]
    )

    return (
        <ConnectionProvider endpoint={WOOF_RPC_ENDPOINT}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{props.children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    )
}

export const WoofWalletSelectButton = () => (
    <WalletButtonContainer className="woof-connect-wallet-container">
        <WalletMultiButton className="woof-connect-wallet-button" />
    </WalletButtonContainer>
)
